// extracted by mini-css-extract-plugin
export var column = "Slider__column__o6cTc";
export var flex = "Slider__flex__oojQb";
export var flexColumn = "Slider__flexColumn__aCjkV";
export var gap1 = "Slider__gap1__dR5BC";
export var gap2 = "Slider__gap2__PxSoB";
export var gap3 = "Slider__gap3__X1Qrd";
export var gap4 = "Slider__gap4__WW4SP";
export var gap5 = "Slider__gap5__KF_r1";
export var row = "Slider__row__tmymi";
export var sliderBase = "Slider__sliderBase__oA5Va";
export var sliderRangeBase = "Slider__sliderRangeBase__j4Wal";