// extracted by mini-css-extract-plugin
export var button = "ButtonGroup__button__lqU88";
export var buttonInactive = "ButtonGroup__buttonInactive__TVBLX";
export var buttonInactiveDarkerGray = "ButtonGroup__buttonInactiveDarkerGray__Sdtvf";
export var buttonLeft = "ButtonGroup__buttonLeft__CoGCx";
export var buttonRight = "ButtonGroup__buttonRight__AGf7a";
export var column = "ButtonGroup__column__XET7V";
export var flex = "ButtonGroup__flex__dMV7o";
export var flexColumn = "ButtonGroup__flexColumn__MK8_b";
export var gap1 = "ButtonGroup__gap1__khjNj";
export var gap2 = "ButtonGroup__gap2__LGvyZ";
export var gap3 = "ButtonGroup__gap3__flCCu";
export var gap4 = "ButtonGroup__gap4__PAlmR";
export var gap5 = "ButtonGroup__gap5__zd1vp";
export var row = "ButtonGroup__row__LHdcV";
export var wrapper = "ButtonGroup__wrapper__fPYLU";