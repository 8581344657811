// extracted by mini-css-extract-plugin
export var column = "SimplePricingMulticloud__column__bekZD";
export var flex = "SimplePricingMulticloud__flex__gxboI";
export var flexColumn = "SimplePricingMulticloud__flexColumn__WYFx6";
export var gap1 = "SimplePricingMulticloud__gap1__TJNxN";
export var gap2 = "SimplePricingMulticloud__gap2__WsL7p";
export var gap3 = "SimplePricingMulticloud__gap3__LyuZI";
export var gap4 = "SimplePricingMulticloud__gap4__REvZ0";
export var gap5 = "SimplePricingMulticloud__gap5__EF4Rq";
export var price = "SimplePricingMulticloud__price__QJW_O";
export var priceRectangle = "SimplePricingMulticloud__priceRectangle__Ie0i3";
export var priceRectangleText = "SimplePricingMulticloud__priceRectangleText__ThZMC";
export var priceRectanglesRow = "SimplePricingMulticloud__priceRectanglesRow__RJTY_";
export var row = "SimplePricingMulticloud__row__FkQu6";
export var subtitle = "SimplePricingMulticloud__subtitle__kqKTb";
export var title = "SimplePricingMulticloud__title__WJjeE";
export var wrapper = "SimplePricingMulticloud__wrapper__WVZD7";