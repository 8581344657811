// extracted by mini-css-extract-plugin
export var answerWithLink = "FAQMulticloud__answerWithLink__UGDrj";
export var column = "FAQMulticloud__column__Cyqle";
export var flex = "FAQMulticloud__flex__m2BtQ";
export var flexColumn = "FAQMulticloud__flexColumn__gh88T";
export var gap1 = "FAQMulticloud__gap1__SIsoY";
export var gap2 = "FAQMulticloud__gap2__M57xs";
export var gap3 = "FAQMulticloud__gap3___7l4M";
export var gap4 = "FAQMulticloud__gap4__Qtw9u";
export var gap5 = "FAQMulticloud__gap5__xL0Z8";
export var heading = "FAQMulticloud__heading___u8eJ";
export var row = "FAQMulticloud__row__k4SwG";
export var wrapper = "FAQMulticloud__wrapper__gQIxH";