// extracted by mini-css-extract-plugin
export var buttonsRow = "JumbotronMulticloud__buttonsRow__Q2xpt";
export var column = "JumbotronMulticloud__column__OhA83";
export var flex = "JumbotronMulticloud__flex__HVOWz";
export var flexColumn = "JumbotronMulticloud__flexColumn__BnvRg";
export var freeMonthsMessage = "JumbotronMulticloud__freeMonthsMessage__szwxp";
export var gap1 = "JumbotronMulticloud__gap1__j25pH";
export var gap2 = "JumbotronMulticloud__gap2__OrN6l";
export var gap3 = "JumbotronMulticloud__gap3__fup2E";
export var gap4 = "JumbotronMulticloud__gap4__PBgpx";
export var gap5 = "JumbotronMulticloud__gap5__zzSwd";
export var heading = "JumbotronMulticloud__heading__S36h9";
export var row = "JumbotronMulticloud__row__U9ZFh";
export var wrapper = "JumbotronMulticloud__wrapper__qH8Cz";