// extracted by mini-css-extract-plugin
export var column = "LearnMoreSectionMulticloud__column__Kv_DZ";
export var flex = "LearnMoreSectionMulticloud__flex__kswFh";
export var flexColumn = "LearnMoreSectionMulticloud__flexColumn__OHJXv";
export var gap1 = "LearnMoreSectionMulticloud__gap1__peY0t";
export var gap2 = "LearnMoreSectionMulticloud__gap2__KcCej";
export var gap3 = "LearnMoreSectionMulticloud__gap3__kyKC6";
export var gap4 = "LearnMoreSectionMulticloud__gap4__FHbSA";
export var gap5 = "LearnMoreSectionMulticloud__gap5__XOEeq";
export var header = "LearnMoreSectionMulticloud__header__Ala6h";
export var row = "LearnMoreSectionMulticloud__row__OB1JN";