// extracted by mini-css-extract-plugin
export var column = "PricingEstimatorSliderGroup__column__R9wd9";
export var flex = "PricingEstimatorSliderGroup__flex__wVRsP";
export var flexColumn = "PricingEstimatorSliderGroup__flexColumn__h10nO";
export var gap1 = "PricingEstimatorSliderGroup__gap1__p08Wi";
export var gap2 = "PricingEstimatorSliderGroup__gap2__RXh2e";
export var gap3 = "PricingEstimatorSliderGroup__gap3__pnNWX";
export var gap4 = "PricingEstimatorSliderGroup__gap4__a6DC6";
export var gap5 = "PricingEstimatorSliderGroup__gap5__gdzMp";
export var icon = "PricingEstimatorSliderGroup__icon__RCT67";
export var iconDesktop = "PricingEstimatorSliderGroup__iconDesktop__LQBzK";
export var iconMobile = "PricingEstimatorSliderGroup__iconMobile__YuGAp";
export var pricing = "PricingEstimatorSliderGroup__pricing__Qxv1A";
export var pricingDescription = "PricingEstimatorSliderGroup__pricingDescription__e4wSt";
export var pricingHeading = "PricingEstimatorSliderGroup__pricingHeading__NLNDd";
export var row = "PricingEstimatorSliderGroup__row__bHhHJ";
export var sliderGroupWrapper = "PricingEstimatorSliderGroup__sliderGroupWrapper__wFk6z";